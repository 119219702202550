(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('DynamicFormController', DynamicFormController);

    DynamicFormController.$inject = ['$mdSidenav', '$q', '$log', '$timeout', '$scope', '$state', '$stateParams', '$mdDialog', '$uibModal', '$translate', '$translatePartialLoader',
            'order', 'report', 'currentForm', 'formDefinitions', 'formData', 'validationErrors', 'reportErrors',
            'AppraiserUser', 'DynamicForm', 'AppraisalReport', 'AppraisalOrder', 'account', 'AppraisalState',
            'Enum', 'DataUtils', 'DateUtils', 'File', 'Blob', 'FileSaver', 'AlertService', 'Principal', 'Idle', 'isReadOnly', 'Template'];

    function DynamicFormController ($mdSidenav, $q, $log, $timeout, $scope, $state, $stateParams, $mdDialog, $uibModal, $translate, $translatePartialLoader,
            order, report, currentForm, formDefinitions, formData, validationErrors, reportErrors,
            AppraiserUser, DynamicForm, AppraisalReport, AppraisalOrder, account, AppraisalState,
            Enum, DataUtils, DateUtils, File, Blob, FileSaver, AlertService, Principal, Idle, isReadOnly, Template) {

        // Initialize controller model
        var vm = this;
        vm.appraisalOrder = order;
        vm.appraisalReport = report;
        vm.formDefinitions = formDefinitions;
        vm.reportErrors = reportErrors;
        vm.hasErrors = Object.keys(vm.reportErrors).some(function(key) { return vm.reportErrors[key]; });
        vm.reportData = {};
        vm.navSections = [];
        vm.searchStrings = Object.create(null);
        vm.filePickers = Object.create(null);
        vm.fileApis = Object.create(null);
        vm.filePickerWatchers = [];
        vm.enum = Enum;
        vm.isSaving = false;
        vm.isLoadingSection = true;
        vm.tinyMceOptions = {
            setup: function(editor) {
                $timeout(function () {
                    //editor.focus();
                });
            },
            plugins: ['autoresize', 'paste'],
            menubar: false,
            toolbar: "undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat",
            paste_as_text: true,
            browser_spellcheck: true,
            content_css : 'ext/css/base.tinymce.css'
        };

        // 0 - not updated, 1 - subject address updated, 2 - map address updated
        vm.addressUpdatedType = 0;

        // Controller methods
        vm.hasForm = hasForm;
        vm.addForm = addForm;
        vm.removeForm = removeForm;
        vm.openFormDialog = openFormDialog;
        vm.setDirty = setDirty;
        vm.queryOptions = queryOptions;
        vm.removeFromArray = removeFromArray;
        vm.removeItem = removeItem;
        vm.isDeleted = isDeleted;
        vm.isNotDeleted = isNotDeleted;
        vm.removeFile = removeFile;
        vm.downloadFile = downloadFile;
        vm.navigateSection = navigateSection;
        vm.navigateToField = navigateToField;
        vm.getNextSection = getNextSection;
        vm.getPrevSection = getPrevSection;
        vm.navigateNext = navigateNext;
        vm.navigatePrev = navigatePrev;
        vm.sectionChanged = sectionChanged;
        vm.cancel = cancel;
        vm.save = save;
        vm.toggleSidenav = toggleSidenav;
        vm.closeSidenav = closeSidenav;
        vm.getAddress = getAddress;
        vm.isReadOnly = isReadOnly;
        vm.shouldBeDisabled = shouldBeDisabled;
        vm.canSubmitThirdParty = canSubmitThirdParty;
        vm.submitReport = submitReport;
        vm.saveTemplate = saveTemplate;
        vm.loadExistingTemplate = loadExistingTemplate;
        vm.removeEntries = removeEntries;
        vm.existsInArray = existsInArray;

        vm.minDate = new Date("January 1, 1970 00:00:00");
        vm.maxDate = new Date("January 1, 2038 00:00:00"); // This will have to be adjusted in 20 years
        vm.today = new Date();

        //Grab the assigned appraiser user
        vm.appraiserUser = AppraiserUser.get({id:order.assignedAppraiserId});
        vm.isCosigner = account && account.appraiserUser && vm.appraisalReport.cosignerId === account.appraiserUser.id;

        // Check if consent has been notified
        checkAppraiserNotifiedConsentMissing();

        setPlaceholderLenderName(vm.appraisalOrder)

        function checkAppraiserNotifiedConsentMissing() {
            if (vm.isReadOnly || vm.appraisalOrder.appraiserNotifiedConsentMissing) return;

            for (var i = 0; i < vm.appraisalOrder.appraisalOrderContacts.length; i++) {
                if (vm.appraisalOrder.appraisalOrderContacts[i].providedConsent) return;
            }

            var confirm = $mdDialog.alert()
                  .title('Consent Missing!')
                  .textContent('Consent to take pictures may not have been acquired by the Originator.')
                  .ariaLabel('appraiserNotifiedConsentMissing')
                  .ok('OK');

            $mdDialog.show(confirm).then(function() {
                AppraisalOrder.missingConsentNotified({id: vm.appraisalOrder.id}, {}, function(data) {
                    vm.appraisalOrder = data;
                });
            });

        }

        if(currentForm) {
            // Initialize reportData
            formDefinitions.forEach(function(definition) {
                vm.reportData[definition.name] = (definition.name === currentForm) ? formData : (
                    DynamicForm.get({ reportId: report.id, formName: definition.name})
                );

                console.log("vm.reportData[definition.name]", vm.reportData[definition.name])
            });

            // Identify the current form
            vm.currentForm = currentForm;

            // Identify the current section
            // TODO: Select the first unstarted section instead of the first section
            var currentFormSections = formDefinitions.find(function(definition) {
                return definition.name === vm.currentForm;
            }).sections;
            var currentFormFirstSection = currentFormSections.length ? currentFormSections[0].name : null;
            vm.currentSection = $stateParams.section || currentFormFirstSection;

            // Identify the current field
            if($stateParams.field) {
                // Override the current section using the field path, if necessary
                vm.currentSection = currentFormSections.length ? $stateParams.field.split('.')[1] : null;
            }

            init();
        } else {
            // If there are no forms on the report, open form selection dialog before initializing
            openFormDialog().then(function() {
                vm.currentForm = vm.formDefinitions[0].name;
                vm.currentSection = vm.formDefinitions[0].sections.length ?
                    vm.formDefinitions[0].sections[0].name : null;
                init();
            });
        }

        /**
         * Load up the initial form template and set up model watchers.
         */
        function init() {
            // Update state parameters if they are not already set
            if(!$stateParams.form) {
                $stateParams.form = vm.currentForm;
                $stateParams.section = vm.currentSection;
                $state.go('.', $stateParams);
            }

            // Initialize form data then render the form template
            generateNavSections();
            initData();
            setSectionTemplate();
            setTranslationPrefix();

            // TODO: Fix root issue for below fix - section-validation directive not validating forms separately from sections
            if(vm.hasForm('costApproach')){validationErrors = AppraisalReport.validateFields({ id: report.id });}
            if(vm.hasForm('costApproachCuspap2024')){validationErrors = AppraisalReport.validateFields({ id: report.id });}

            // Prompt to save form data when leaving the page
            // TODO: use window.onbeforeunload to do this for all page navigation
            $scope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams, options) {
                // Cancel navigation if there are changes to the form
                if($scope.editForm.$pristine || options.skipSavePrompt || !Principal.isAuthenticated()) return;
                options.skipSavePrompt = true;
                event.preventDefault();

                // Ask to save form data before navigating
                var confirm = $mdDialog.confirm()
                    .title('You have unsaved changes to this form')
                    .textContent('Would you like to save your changes before leaving this page?')
                    .ok('Save')
                    .cancel('Discard Changes');

                $mdDialog.show(confirm).then(function() {
                    // Save data, then resume navigation
                    saveData(true, false).then(function() {
                        $state.go(toState, toParams, options);
                    });
                }, function() {
                    // Discard changes; do nothing and resume navigation
                    $state.go(toState, toParams, options);
                });

                // TODO: provide a 'cancel' option to cancel the navigation
            });

            // Auto save during the warning period if idle too long
            $scope.$on('IdleWarn', function(e, countdown) {
                save(false);
            });

            $scope.$on('IdleEnd', function(e, countdown) {

            });
        }

        /**
         * Check if the specified form exists on the report
         * @param  {String}  formName The name of the form
         * @return {Boolean}          True if the form exists on the current report, otherwise false.
         */
        function hasForm(formName) {
            return angular.isDefined(vm.reportData[formName]);
        }

        /**
         * Add the specified form to the report
         * @param {String} formName The name of the form
         */
        function addForm(formName) {
            if(hasForm(formName)) {
                $log.warn("Cannot add a form that already exists:", formName);
                return;
            }

            // Create the form
            return DynamicForm.init({reportId: report.id, formName: formName}, null).$promise.then(function(addFormResult) {
                report.forms.push(addFormResult.form);
                vm.reportData[formName] = addFormResult.formData;
                vm.formDefinitions.push(addFormResult.formDefinition);
                generateNavSections();

                // Revalidate the report before returning the addFormResult
                validationErrors = AppraisalReport.validateFields({ id: report.id });
                return revalidate().then(function() {
                    // After adding a form, the content of the form does not immediately show up.
                    // Forcing a reload fixes this.
                    $state.reload();
                    return addFormResult;
                });
            });
        }

        /**
         * Remove the specified form from the report. If the form dialog is currently open, re-open
         * it after the confirmation dialog closes
         * @param  {String}  formName The name of the form to delete
         * @param  {Event}   $event   Optional. The event that triggered this method.
         * @return {Promise}          A promise that is resolved with the result of the confirm dialog.
         */
        function removeForm(formName, $event) {
            if(!hasForm(formName)) {
                $log.warn("Cannot remove form that does not exist");
                return;
            }

            // Check if we will need to reopen the form dialog
            var formDialogOpen = !!vm.formDialog;
            var isStarted = vm.reportData[formName].$started;
            var reopenFormDialog = formDialogOpen && isStarted;

            // Show a confirmation dialog if the form has been started
            var confirmPromise = !isStarted ? $q.when(null) :
                getConfirmMessage(formName).then(function(confirmMessage) {
                    return $mdDialog.show($mdDialog.confirm()
                        .title('Are you sure you would like to delete this form?')
                        .htmlContent(confirmMessage)
                        .ariaLabel('Delete Form')
                        .targetEvent($event)
                        .ok('Okay')
                        .cancel('Cancel'));
                });


            function getConfirmMessage(formName) {
                return $translate("valueconnectApp.form." + formName).then(function(formNameTranslation) {
                    var confirmTranslationKey = "valueconnectApp.appraisalReportForm.delete.confirm";
                    return $translate(confirmTranslationKey, {formName: formNameTranslation});
                });
            }

            // Wait for confirmation to resolve then delete the form
            return confirmPromise.then(function() {
                return DynamicForm.delete({reportId: $stateParams.id, formName: formName}, null).$promise.then(function() {
                    // Remove from report object
                    var formIndex = report.forms.findIndex(function(form) {
                        return form.fieldName === formName;
                    });
                    report.forms.splice(formIndex, 1);

                    // Remove from definitions array
                    var definitionIndex = vm.formDefinitions.findIndex(function(definition) {
                        return definition.name === formName;
                    });
                    vm.formDefinitions.splice(definitionIndex, 1);

                    // Remove from report data and regenerate nav sections
                    vm.reportData[formName] = undefined;
                    generateNavSections();

                    // Remove associated validation errors and broadcast a revalidation event
                    var removedErrors = Object.keys(validationErrors).filter(function(fieldKey) {
                        return fieldKey.startsWith(formName);
                    })
                    if(removedErrors.length) {
                        removedErrors.forEach(function(removedField) {
                            validationErrors[removedField] = undefined;
                        });
                        revalidate();
                    }

                    // Update model and URL if the current form was deleted
                    if(vm.currentForm === formName) {
                        vm.currentForm = null;
                        vm.currentSection = null;
                        vm.sectionTemplate = null;
                        vm.translationPrefix = null;
                        $state.go('.', {
                            form: undefined,
                            section: undefined,
                            field: undefined
                        });
                    }
                });
            }).finally(function() {
                // Reopen the form dialog if necessary
                if(reopenFormDialog) openFormDialog();
            });
        }

        /**
         * Open dialog to select form inserts.
         * TODO: If the current form is removed, set a new current form using changeSection()
         *       Don't do this if initialization hasn't yet been completed
         * @param  {Event} $event Event that triggered the modal
         * @return {Promise}      Promise resolved with the modal result
         */
        function openFormDialog($event) {
            vm.formDialog = $mdDialog.show({
                templateUrl: 'app/entities/appraisal-report/appraisal-report-dialog.html',
                controller: 'AppraisalReportDialogController',
                controllerAs: 'vm',
                locals: {
                    report: report,
                    hasForm: hasForm,
                    addForm: addForm,
                    removeForm: removeForm
                }
            }).finally(function() {
                vm.formDialog = null;
            });

            return vm.formDialog;
        }

        /**
         * There seems to be a bug with md-select, so we have to use this to set its dirty state manually
         */
        // TODO: support linked fields using the getInputs method
        function setDirty(fieldName) {
            $timeout(function() {
                if(!$scope.editForm[fieldName]) {
                    $log.error('Cannot set field as dirty because it does not exist on the form:', fieldName);
                } else {
                    $scope.editForm[fieldName].$setDirty();
                }
            });
        }

        /**
         * Query an enum list using the provided search text
         *
         * @param  {String} searchText The text to search for
         * @param  {Array}  model      The existing model values; they will be excluded from returned results
         * @param  {String} enumKey    The name of the enum to search against
         * @return {Array}             The list of matching values
         */
        // TODO: Do this in the enum service instead (which supports translations)
        function queryOptions(searchText, model, enumKey) {
            // Return an emtpy array if the enum key is not valid
            if(angular.isUndefined(Enum[enumKey])) {
                $log.error('Cannot query an invalid enum type: ' + enumKey);
                $log.error('Valid enum keys:', Object.keys(Enum));
                return [];
            }

            var searchRegex = new RegExp('^' + searchText, 'i');
            return Enum[enumKey].filter(function(option) {
                return searchRegex.test(option) && !model.includes(option);
            });
        }

        /**
         * Show dialog to confirm the remove item action
         * @param  {Array} array The array to remove the element from
         * @param  {Int}   index The index of the element to remove, relative to the non-deleted items in the array
         */
        function removeFromArray(array, index) {
            var confirm = $mdDialog.confirm()
                  .title('Remove Item')
                  .textContent('Are you sure you would like to remove this item?')
                  .ariaLabel('removeFromArrayConfirmDialog')
                  .ok('Yes')
                  .cancel('No');

            return $mdDialog.show(confirm).then(function() {
                removeItem(array, index);
            });
        }

        vm.removeFromTabs = function (array, index) {
            var confirm = $mdDialog.confirm()
                  .title('Remove Item')
                  .textContent('Are you sure you would like to remove this item?')
                  .ariaLabel('removeFromArrayConfirmDialog')
                  .ok('Yes')
                  .cancel('No');

            return $mdDialog.show(confirm).then(function() {
                vm.removeTabItem(array, index);
            });
        }
        vm.removeTabItem = function removeTabItem(array, index) {
            var count = 0;
            // Find and remove element from array
            var element = array[index];
            if(!element || !element.__id__) {
                array = array.splice(index, 1);
            } else {
                $scope.editForm.$setDirty();
                element.__deleted__ = true;
            }
        }

        /**
         * Remove the element at the specified index from the array, modifying the original array.
         * The index is relative to elements that have not been removed (i.e. deleted items are not counted).
         * If this is a new element (i.e. '__id__' is not set) remove the element directly.
         * Otherwise, set the '__deleted__' property of the element to 'true' mark the form as dirty
         * @param  {Array} array The array to remove the element from
         * @param  {Int}   index The index of the element to remove, relative to the non-deleted items in the array
         */
        function removeItem(array, index) {
            // Update index to account for deleted elements
            for (var i = 0 ; i <= index ; i++) {
                if(isDeleted(array[i])) index ++;
            }

            // Find and remove element from array
            var element = array[index];
            if(!element || !element.__id__) {
                array = array.splice(index, 1);
            } else {
                $scope.editForm.$setDirty();
                element.__deleted__ = true;
            }
        }

        /**
         * Check if this element is deleted
         * Return false If the element is null, or if the '__deleted__' key is not set
         */
        function isDeleted(element) {
            return element && element.__deleted__ && element.__deleted__ === true;
        }

        /**
         * Check if this element is not deleted.
         * Return true if the element is null, or if the '__deleted__' key is not set
         */
        function isNotDeleted(element) {
            return !isDeleted(element);
        }

        /**
         * Switch to the specified form and section of the report builder. This method does not
         * attempt to save the current section before navigating. If the form and section specified
         * are equal to the current form and section, this method does nothing. If a new section is
         * currently being loaded, this method does nothing.
         * NOTE: This is never called directly anywhere so we could combine it with navigateSection().
         * @param  {String} formName    The form to switch to
         * @param  {String} sectionName The section to switch to, or null if the form does not have sections
         * @param  {String} fieldName   Optional. The fieldName to focus after navigation
         */
        function changeSection(formName, sectionName, fieldName) {
            if(vm.currentForm === formName &&
               vm.currentSection === sectionName) return;   // Ignore if the section has not really changed
            if(vm.isLoadingSection) return;                 // Ignore if a section is already loading
            vm.isLoadingSection = true;                     // Show the loading bar
            vm.currentForm = formName;                      // Update the current form
            vm.currentSection = sectionName;                // Update the current section
            resetFilePickers();                             // Unsubscribe all file picker watchers and reset tracking arrays
            initData();                                     // Initialize data for the current section
            setSectionTemplate();                           // Set the template path; will trigger sectionChanged()
            setTranslationPrefix();                         // Set the translation prefix
            $state.go('.', {                                // Update the URL (field parameter is removed)
                form: formName,
                section: sectionName,
                field: fieldName
            });

            // Scroll back to top of form
            var $scrollTarget = angular.element('#dynamic-form');
            angular.element("html,body").animate({scrollTop: $scrollTarget.offset().top}, "slow");
        }

        /**
         * This method is called by the ng-include 'onLoad' event of the section template.
         */
        function sectionChanged() {
            // Initialize existing file pickers and set up a watch to observe
            // any that are dynamically added/removed from the form
            initFilePickers(true);
            vm.watchFilePickers = $scope.$watchCollection('vm.filePickers', function() {
                initFilePickers(false);
            });

            // Navigate to the specified field (if it exists)
            if($stateParams.field) $timeout(function() { focusField($stateParams.field); });

            // trigger a re-validation
            revalidate().then(function() {
                vm.isLoadingSection = false;
            });
        }

        /**
         * Scroll to, and focus the associated input for the specified field. If the
         * specified field is not in the currently loaded form section, save the form and navigate
         * to the correct section before focusing the field.
         * @param  {String} fieldKey The field to navigate to
         */
        function navigateToField(fieldKey) {
            // Identify the target form
            var fieldSegments = fieldKey.split('.');
            var targetForm = fieldSegments.shift();

            // Identify the target section
            var targetSections = vm.formDefinitions.find(function(definition) {
                return definition.name === targetForm;
            }).sections;
            var targetSection = targetSections.length ? fieldSegments.shift() : null;

            if(vm.currentForm !== targetForm || vm.currentSection !== targetSection) {
                // Save the form, navigate to the new section, and focus the field
                navigateSection(targetForm, targetSection, fieldKey);
            } else {
                // Navigate to a field in the current section
                focusField(fieldKey);
            }
        }

        /**
         * Scroll to, and focus the input for the specified field. Will log an error
         * if the specified field does not exist on the current form & section.
         * @param  {String} fieldKey    The path of the form field, including the form name
         */
        function focusField(fieldKey) {
            // Do nothing if the field key is a form section
            if(fieldKey === [vm.currentForm,vm.currentSection].filter(Boolean).join('.'))
                return;

            // Remove the form name from the start of the fieldKey
            fieldKey = fieldKey.substring(vm.currentForm.length + 1, fieldKey.length);

            // Find the input element by its 'name' attribute
            // NOTE: this will only select the first element for fields with multiple inputs
            var input = angular.element("[name='"+fieldKey+"']").first();

            // Do nothing if we failed to find the field
            if(!input.length) {
                $log.error("Cannot navigate to non-existent field:", fieldKey);
                return;
            }

            // If this is inside an md-tabs, activate the containing md-tab, then focus when animation completes
            var tabId = input.closest('md-tab-content').attr('id');
            if(tabId) {
                $timeout(function() {
                    input.closest('md-tabs').find("md-tab-item[aria-controls='"+tabId+"']").click();
                    $timeout(function() { input.focus(); }, 500);
                });
                return;
            }

            // Add a tabIndex to file inputs so they can be focused
            if(input.hasClass('lf-ng-md-file-input')) input.attr('tabindex', -1);

            // Focus the first checkbox for vc-checklist elements
            if(input.is("vc-checklist")) input = input.find('.checklist-item:first-child md-checkbox');

            // Focus the first checkbox for vc-checklist elements
            if(input.is("md-autocomplete")) input = input.find('input:first-child');

            // Focus the first checkbox for vc-checklist elements
            if(input.is("md-datepicker")) input = input.find('input.md-datepicker-input');



            // Account for different offsets for TinyMCE
            var inputOffset = 150;
            if (input.attr('ui-tinymce') !== undefined) inputOffset = 250;

            // Scroll to the element then focus it
            angular.element('html, body').animate({
                scrollTop:  input.offset().top - inputOffset
            }, {
                duration: 500,
                always: function() {
                    // If TinyMCE
                    console.log(input);
                    console.log(input.attr('ui-tinymce') !== undefined);
                    if (input.attr('ui-tinymce') !== undefined) {
                        tinymce.execCommand('mceFocus',false, input.attr('id'));
                    } else {
                        input.focus();
                    }


                }
            });

        }

        /**
         * Update the form that is currently loaded based on the current form and section.
         * This will automatically update the src of the ng-include directive on the form,
         * which will trigger the sectionChanged() handler below upon completion.
         * Before loading the next template, the current template is unloaded.
         */
        function setSectionTemplate() {
            vm.sectionTemplate = null;
            vm.isLoadingSection = true;
            $timeout(function() {
                var sectionPath = vm.currentForm + (vm.currentSection ? '/' + vm.currentSection : '');
                vm.sectionTemplate = 'app/report-builder/' + sectionPath + '.html';
            });
        }

        function setTranslationPrefix() {
            var sectionPath = vm.currentForm + (vm.currentSection ? '.' + vm.currentSection : '');
            vm.translationPrefix = 'valueconnectApp.form.' + sectionPath;
        }

        /**
         * Wait for validations request to complete, then broadcast event to fields to update their validations
         * @return {Promise} Promise that is resolved when the validations have been updated
         */
        function revalidate() {
            return validationErrors.$promise.then(function(errors) {
                return $timeout(function() {
                    var sectionData = vm.currentSection ?
                        vm.reportData[vm.currentForm][vm.currentSection] :
                        vm.reportData[vm.currentForm];
                    $scope.$broadcast('validationsUpdated', errors, sectionData, vm.reportData);
                    return errors;
                });
            });
        }

        /**
         * Initialize form data for the current section using the form definition.
         * Assumes the top level object is a form section (not an array)
         */
        // TODO: The data should come already initialized from the server so that this isn't necessary
        function initData() {
            var formDefinition = vm.formDefinitions.find(function(definition) { return definition.name === vm.currentForm; });

            if(!vm.currentSection) {
                vm.reportData[vm.currentForm] = initSectionData(formDefinition, vm.reportData[vm.currentForm]);
            } else {
                var sectionDefinition = formDefinition.sections.find(function(definition) { return definition.name === vm.currentSection; });
                var formData = vm.reportData[vm.currentForm];
                formData[vm.currentSection] = initSectionData(sectionDefinition, formData[vm.currentSection]);
            }

            // Set formData object to the initialized form data
            // TODO: get rid of this 'formData' object, and use a more restricted 'sectionData' object instead
            vm.formData = vm.reportData[vm.currentForm];
        }


        /**
         * Using the form definition, merge the existing form data with an object that
         * reflects an empty form for the specified section.
         *
         * This is required to ensure any collection fields are initialized as arrays and not objects.
         *
         * This method is also responsible for deserializing data for any date fields.
         *
         * @return {Object} An initialized model for the form
         */
        function initSectionData(sectionDefinition, sectionData) {
            // Initialize if there is no existing data
            if(angular.isUndefined(sectionData)) {
                sectionData = {};
            }

            // Deserialize data for all date fields
            sectionDefinition.dateFields.forEach(function(fieldName) {
                sectionData[fieldName] = DateUtils.convertDateTimeFromServer(sectionData[fieldName]);
            });

            // Recurse on sub-sections
            sectionDefinition.sections.forEach(function(section) {
                sectionData[section.name] = initSectionData(section, sectionData[section.name]);
            });

            // Recurse on collections
            sectionDefinition.collections.forEach(function(collection) {
                sectionData[collection.name] = initCollectionData(collection, sectionData[collection.name]);
            });

            return sectionData;
        }

        /**
         * Using the form definition, merge the existing form data with an object that
         * reflects an empty form for the specified section.
         *
         * This is required to ensure any collection fields are initialized as arrays and not objects.
         *
         * This method is also responsible for deserializing data for any date fields.
         *
         * @return {Object} An initialized model for the form
         */
        function initCollectionData(collectionDefinition, collectionData) {
            var arrayLength = collectionDefinition.initLength || 0;

            // Initialize if there is no existing data
            if(angular.isUndefined(collectionData)) {
                collectionData = Array.apply(null, Array(arrayLength)).map(function () {
                    return {};
                });
            } else if (angular.isString(collectionData)) {
                // e.g., converting from enum to multiselect
                collectionData = [collectionData];
            } else {
                // TODO: use a minLength property to specify this behaviour instead if initLength
                while(collectionData.length < arrayLength) collectionData.push({});
            }

            console.log('this is collectionData', collectionData)

            // Iterate through existing array elements and initialize each one
            console.log('collectionData', collectionData)
            collectionData.forEach(function(arrayElement, index) {
                // Deserialize data for all date fields
                collectionDefinition.dateFields.forEach(function(fieldName) {
                    if(arrayElement && arrayElement[fieldName]) {
                        var deserializedDate = DateUtils.convertDateTimeFromServer(collectionData[index][fieldName]);
                        collectionData[index][fieldName] = deserializedDate;
                    }
                });

                // Recurse on sub-sections
                collectionDefinition.sections.forEach(function(section) {
                    collectionData[index][section.name] = initSectionData(section, collectionData[index][section.name]);
                });

                // Recurse on collections
                collectionDefinition.collections.forEach(function(collection) {
                    collectionData[index][collection.name] = initCollectionData(collection, collectionData[index][collection.name]);
                });
            });
            return collectionData;
        }

        function cancel() {
            // TODO: show confirmation dialog to let user know changes will be lost
            $state.go('appraisal-order-detail', {id: report.appraisalOrderId});
        }

        function downloadFile(file) {
            File.download({id: file.id, appraisalOrderId: report.appraisalOrderId}, function(response) {
                var fileData = new Blob([response.data], { type: file.contentType});
                FileSaver.saveAs(fileData, file.fileName);
            });
        }

        /**
         * Saves the form data in the current section
         * @param  {boolean} force      If true, the form will be saved even if it is in the 'pristine' state. Defaults to false.
         * @param  {boolean} revalidate If true, re-fetch validation errors for the form after saving. Defaults to true.
         * @return {promise} A promise that will be resolved once the save operation has been completed
         */
        function saveData(force, revalidate) {
            try {
                if(vm.reportData && vm.reportData.thirdParty) {
                    if (vm.reportData.thirdParty.report
                        && !vm.reportData.thirdParty.report.id
                        && vm.reportData.thirdParty.report.type !== "application/pdf") {
                        throw "report";
                    }
                    if (vm.reportData.thirdParty.redactedReport
                        && !vm.reportData.thirdParty.redactedReport.id
                        && vm.reportData.thirdParty.redactedReport.type !== "application/pdf") {
                        throw "redactedReport";
                    }
                }

                force = angular.isUndefined(force) ? false : force;
                revalidate = angular.isUndefined(revalidate) ? true : revalidate;

                // Do nothing if no form data was changed, or if there is already a save in progress
                // or if the current user is CCR
                if (vm.isSaving || (!force && $scope.editForm.$pristine) || vm.isReadOnly) {
                    return $q.when(null);
                }
                var updateType;
                return $q(function (resolve, reject) {
                    try {
                        vm.isSaving = true;


                        // Create a multipart FormData object with the submission data and any file submissions
                        var multipartFormData = generateSubmissionData();

                        // Update address position
                        if (vm.addressUpdatedType !== 0) {
                            updateAddressPosition();
                        }
                        // Save the data and resolve or reject the promise
                        DynamicForm.save({
                            reportId: report.id,
                            formName: vm.currentForm
                        }, multipartFormData, function (result) {
                            // Mark the form as pristine after saving
                            $scope.editForm.$setPristine();

                            // Refresh validation
                            if (revalidate) {
                                validationErrors = AppraisalReport.validateFields({id: report.id});
                            }

                            vm.reportData[vm.currentForm] = result;

                            // Clear selections from all file pickers
                            Object.keys(vm.fileApis).forEach(function (fileName) {
                                vm.fileApis[fileName].removeAll();
                            });

                            resolve(result);
                        }, function (result) {
                            reject(result);
                        });
                    } catch (e) {
                        // Log an error and reject the promise
                        $log.error(e);
                        reject(e);
                    }
                }).catch(function (error) {
                    // Show modal message on failure
                    console.log(error);
                    var dialogPromise = $mdDialog.show($mdDialog.confirm()
                        .title('Error Saving Form')
                        .textContent('An error has occurred while saving your form submission. You must reload the form to continue editing.')
                        .ok('Reload Form')
                        .cancel('Cancel'));
                    reject(error);

                    // Reload state on modal success; Reject the returned promise regardless of modal result
                    var deferred = $q.defer();
                    dialogPromise.then(function () {
                        return $state.go($state.current, {}, {reload: true, skipSavePrompt: true});
                    })
                        .finally(function () {
                            deferred.reject(error);
                        });
                    return deferred.promise;
                }).finally(function () {
                    vm.isSaving = false;
                });
            } catch (e) {
                $mdDialog.show($mdDialog.alert()
                    .title('Error Saving Form')
                    .textContent("Only PDF files are allowed")
                    .ok('Cancel'));
                vm.formData[e] = null;
                vm.fileApis[e].removeAll();
                vm.filePickers[e].$dirty = true;
                console.log(e);
            }
        }

        /**
         * Save the current form data, then reinitialize the current section with the new data
         * @param  {Boolean} force If true, save even if the form is in a pristine state
         * @return {Promise}       Promise resolved when the save operation has completed
         */
        function save(force) {
            return saveData(force).then(function(result) {
                // Reinitialize this section with the new form data
                if(result) {
                    initData();
                    $scope.$broadcast('formDataReinitialized', result);
                    revalidate();
                }
            });
        }

        /**
         * Generate the multi-part FormData object to send with the save request to the server. The
         * object will have 'jsonData' section with the modified for data and each other section
         * represents a file upload.
         *
         * To generate the 'jsonData' section, perform a deep clone of the current form/section
         * model, setting any unmodified fields to 'undefined'. Any properties without an associated
         * form field will be logged as an error and ignored.
         *
         * @return {FormData} The object to be submitted to the server
         */
        function generateSubmissionData() {
            var currentFormData = vm.reportData[vm.currentForm];
            var multipartData = new FormData();
            var modifiedFormData = {};

            if(vm.currentSection) {
                modifiedFormData[vm.currentSection] = getModifiedData(currentFormData[vm.currentSection], multipartData, vm.currentSection);
            } else {
                modifiedFormData = getModifiedData(currentFormData, multipartData, '');
            }

            multipartData.append('jsonData', angular.toJson(modifiedFormData));

            return multipartData;
        }

        /**
         * Perform a deep clone of the provided model,
         * setting any unmodified fields to 'undefined'. Any properties without
         * an associated form field will be implicitly assumed to be 'dirty'.
         *
         * @param  {Object} model      The object to clone
         * @param  {FormData} multipartData A form data object to add files to as multipart data sections
         * @param  {String} keyName    Prefix for this field on the form
         * @return {Object}            The cloned object
         */
        function getModifiedData(model, multipartData, keyName, isTemplate) {
            keyName = angular.isUndefined(keyName) ? '' : keyName;
            var type = typeof model;

            if(angular.isUndefined(model)) {
                return undefined;
            }
            else if(model === null) {
                return null;
            }
            else if(isFileModel(keyName, model)) {
                if (isTemplate) return undefined;

                var picker = vm.filePickers[keyName];

                // Return the model directly for URL uploads
                if(model.fileUrl) return model;

                // Submit nothing if there is no associated picker, or if the file has not been changed
                if(!picker || !picker.$dirty)  return undefined;

                // Submit null (mark as deleted) if the file was removed
                if(!model) return null;

                // We have a new file; add it to the FormData object and set the model to the part name
                if(Object.prototype.toString.call(model) === "[object File]") {
                    var partName = keyName;
                    multipartData.append(partName, model);
                    return partName;
                }

                // Shouldn't get here; this is an error, submit nothing to server
                $log.error("Failed to process file model for field " + keyName, model);
                return undefined;
            }
            else if(keyName.endsWith(".__id__") || (keyName.endsWith(".__deleted__") && model === true)) {
                return isTemplate ? undefined : model;
            }
            else if(isPrimitive(model, type, keyName)) {
                // Make sure this key exists on the form
                var inputs = getInputs(keyName);

                if(inputs.length === 0) {
                    $log.error("The field '" + keyName + "' does not exist on the current form.");
                    return undefined;
                }

                // Return this value if it has been modified, otherwise undefined
                var isModified = inputs.some(function(inputCtrl) { return isTemplate ? true : inputCtrl.$dirty; });
                return isModified ? model : undefined;
            }
            else if(type === 'function') {
                // Execute function to get the calculated value
                return isTemplate ? undefined : model();
            }
            else if(type === 'object' && model.constructor === Array) {
                var arrayClone = [];

                // Recurse on each array element to create the a cloned array
                // TODO: we shouldn't recurse if the element was deleted
                model.forEach(function(element, index) {
                    var recurseKey = keyName + '[' + index + ']';
                    arrayClone[index] = getModifiedData(element, multipartData, recurseKey, isTemplate);
                });

                var areAllElementsNull = arrayClone.every(function(element) { return element == null });
                if (areAllElementsNull) {
                    arrayClone = undefined;
                }

                return arrayClone;
            }
            else if(type === 'object') {
                var objectClone = {};

                for(var property in model) {
                    // Ignore inherited properties and angular properties
                    if (!model.hasOwnProperty(property) || property.startsWith('$')) continue;

                    // Recurse and assign the return value to the clone
                    var recurseKey = keyName + (keyName.length ? '.' : '') + property;
                    objectClone[property] = getModifiedData(model[property], multipartData, recurseKey, isTemplate);
                }
                return objectClone;
            }
            else {
                $log.error("Failed to identify model type for field '" + keyName + "':", model);
                return undefined;
            }

            function isPrimitive(model, type, keyName) {
                return type === "boolean" ||
                    type === "number" ||
                    type === "string" ||
                    type === "symbol" ||
                    model instanceof Date ||  // datepicker
                    model.constructor === Array && getInputs(keyName).length; // multiselect or md-chips
            }

            function isFileModel(keyName, model) {
                return vm.filePickers[keyName] ||
                    Object.prototype.toString.call(model) === "[object File]" ||
                    (model && model.hasOwnProperty('contentType')) ||
                    (model && model.hasOwnProperty('fileUrl'));
            }
        }

        function shouldBeDisabled() {
            return (vm.isSaving || vm.isReadOnly);
        }

        /**
         * Return all input controlls for the specified field.
         * Will return all controls on the edit form with the field name,
         * including those with a suffix matching .$[$]*
         * @param  {String} keyName The input name to search for
         * @return {Array}          Array of controls for the specified field
        */
        // TODO: instead of iterating all keys, iteratively test possible fields names until failure
        function getInputs(keyName) {
            return Object.keys($scope.editForm).filter(function(key) {
                return key === keyName || key.startsWith(keyName + ".$");
            }).map(function(keyName) {
                return $scope.editForm[keyName];
            });
        }

        /**
         * Set the value of the specified field to null and
         * mark the associated file picker as dirty.
         *
         * @param  {String} fieldName The path to the field in the model
         */
        function removeFile(fieldName) {
            var confirm = $mdDialog.confirm()
                  .title('Delete File')
                  .textContent('Are you sure you would like to remove this file?')
                  .ariaLabel('removeFileConfirmDialog')
                  .ok('Yes')
                  .cancel('No');

            $mdDialog.show(confirm).then(function() {
                accessByString(vm.reportData[vm.currentForm], fieldName, null);
                vm.fileApis[fieldName].removeAll();
                vm.filePickers[fieldName].$dirty = true;
            });
        }

        /**
         * Unsubscribe all file picker watchers and empty tracking arrays
         */
        function resetFilePickers() {
            // Unsubscribe the watch on vm.filePickers
            vm.watchFilePickers();

            // Reset vm.filePickers
            vm.filePickers = Object.create(null);

            // Unsubscribe watchers on each file picker and reset the array
            while(vm.filePickerWatchers.length > 0) vm.filePickerWatchers.pop()();
        }

        /**
         * Initialize and registers watcher functions on each file picker in the form.
         * Any existing watchers are removed before adding the new watchers.
         *
         * @param  {Boolean} markPristine @see {@link registerFilePicker}
         */
        function initFilePickers(markPristine) {
            // Unsubscribe any old file watchers
            while(vm.filePickerWatchers.length > 0) vm.filePickerWatchers.pop()();

            // Register each file picker on the form
            for(var fieldKey in vm.filePickers) {
                vm.filePickerWatchers.push(registerFilePicker(fieldKey, vm.filePickers[fieldKey], markPristine));
            }
        }

        /**
         * Initialize the $dirty state of the file picker, and
         * set a watcher to save the form as soon as a file is selected.
         * NOTE: Since we are uploading as soon as the file is selected, the $dirty state is only
         * important for removing files
         *
         * @param  {String}  fieldKey     The field name of the file picker
         * @param  {Object}  filePicker   The filepicker object
         * @param  {Boolean} markPristine If true, marked as pristine ($dirty == false)
         *                                If false, uninitialized pickers will be marked as pristine, but initialized
         *                                pickers will have their $dirty state remain unmodified
         *
         * @return {Function} An unsubscribe function for the watcher on the filepicker
         */
        function registerFilePicker(fieldKey, filePicker, markPristine) {
            if(markPristine || angular.isUndefined(filePicker.$dirty)) filePicker.$dirty = false;
            return $scope.$watch('vm.filePickers["' + fieldKey + '"].length', function(newVal, oldVal) {
                if(newVal === oldVal) return;

                if(newVal > 0) {
                    // Set the value of this field to the selected file and mark it as dirty
                    accessByString(vm.reportData[vm.currentForm], fieldKey, filePicker[0].lfFile);
                    filePicker.$dirty = true;
                    $scope.editForm.$setDirty();

                    // Save the existing form data immediately if a new file is selected
                    // TODO: we don't necessarily need this any more now that we are using multipart data
                    save(true);
                }
            });
        }

        /**
         * Get or set the provided object with the provided value at the specified path.
         * Any missing objects on the path will be created. If the value already exists it
         * will be overwritten.
         *
         * @param  {Object} object The object to update
         * @param  {String} path   The property path to assign the object value (ex. 'foo.property[1]')
         * @param  {Any}    value  The value to add/update on the object. Do not use this argument to get the value instead.
         * @return {Any}           The value that was updated
         */
        function accessByString(object, path, value) {
            path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties (i.e. foo[x] -> foo.x)
            path = path.replace(/^\./, '');           // strip a leading dot
            return arguments.length === 2 ?
                accessByPropertyList(object, path.split('.')) :
                accessByPropertyList(object, path.split('.'), value);
        }

        /**
         * Get or set the provided object with the provided value at the specified path.
         * Any missing objects on the path will be created. If the value already exists it
         * will be overwritten.
         *
         *
         * @param  {Object}       object The object to update
         * @param  {List<String>} path   A list of properties to traverse to access the value being updated
         * @param  {Any}          value  The value to add/update on the object. Do not use this argument to get the value instead.
         * @return {Any}                 The value that was updated
         */
        function accessByPropertyList(object, propertyList, value) {
            var currentProperty = propertyList.shift();

            // Assign value if we are at the end of the path
            if(propertyList.length === 0) {
                if(arguments.length === 2) return object[currentProperty];
                else return object[currentProperty] = value;
            }

            // Create current property if it doesn't exist
            if(!(currentProperty in object))
                object[currentProperty] = {};

            // Recurse
            return arguments.length === 2 ?
                accessByPropertyList(object[currentProperty], propertyList) :
                accessByPropertyList(object[currentProperty], propertyList, value);
        }

        /**
         * Generate a list of sorted navigation sections using the top level form definitions
         * and sections. The resulting value is assigned to vm.navSections.
         */
        function generateNavSections() {
            vm.navSections = vm.formDefinitions.map(function(form) {
                if(form.sections.length) {
                    // Create a nav element for each form section
                    return form.sections.map(function(section) {
                        return {
                            form: form.name,
                            section: section.name,
                            translation: 'valueconnectApp.form.' + form.name + '.' + section.name,
                            displayOrder: section.displayOrder
                        };
                    });
                } else {
                    // Create a nav element for the top level form
                    return [{
                        form: form.name,
                        section: null,
                        translation: 'valueconnectApp.FormType.' + form.name,
                        displayOrder: form.displayOrder
                    }];
                }
            }).reduce(function(a, b) { return a.concat(b); }, [])
            .sort(function(a, b) { return a.displayOrder - b.displayOrder; });
        }

        function getNextSection() {
            var currentIndex = vm.navSections.findIndex(function(nav) {
                return nav.form === vm.currentForm && nav.section === vm.currentSection;
            });
            return currentIndex !== -1 ? vm.navSections[currentIndex+1] : undefined;
        }

        function getPrevSection() {
            var currentIndex = vm.navSections.findIndex(function(nav) {
                return nav.form === vm.currentForm && nav.section === vm.currentSection;
            });
            return currentIndex !== -1 ? vm.navSections[currentIndex-1] : undefined;
        }

        function navigateNext() {
            var nextNav = getNextSection();
            if(nextNav) { return navigateSection(nextNav.form, nextNav.section); }
        }

        function navigatePrev() {
            var prevNav = getPrevSection();
            if(prevNav) { return navigateSection(prevNav.form, prevNav.section); }
        }

        /**
         * Save the form then navigate to the specified section
         * // TODO: watch $stateChangeStart event, and use this method to handle changing of
         * searchParams via normal methods (i.e. ui-sref or browser nav buttons)
         * @param  {String} formName    The form to navigate to
         * @param  {String} sectionName The section to navigate to. May be null or undefined.
         * @param  {String} fieldName   Optional. The fieldName to focus after navigation
         * @return {Promise}            Promise that is resolved onces the navigation completes
         */
        function navigateSection(formName, sectionName, fieldName) {
            if(vm.currentForm === formName && vm.currentSection === sectionName) return;
            $mdSidenav('left').close();

            return saveData().then(function(result) {
                changeSection(formName, sectionName, fieldName);
            }, function(error) {
                // Do nothing on error
            });
        }

        function toggleSidenav(navID) {
            $mdSidenav('left').toggle();
        }

        function closeSidenav() {
            $mdSidenav('left').close();
        }

        function getAddress(subject) {
            return [
                ((subject.address2) ? subject.address2 + " - " + subject.address1 : subject.address1),
                subject.city,
                subject.province,
                subject.postCode
            ].filter(Boolean).join(', ');
        }

        function updateAddressPosition() {
            if (vm.addressUpdatedType === 1) { // 1: geocode lat/lng from subject address
                var geocoder = new google.maps.Geocoder();
                geocoder.geocode({
                    address: vm.getAddress(vm.formData.subject),
                    region: "ca"
                }, function(results, status) {
                    var position = results.length ? results[0].geometry.location : null;
                    if (position) {
                        vm.appraisalOrder.address.lat = position.lat();
                        vm.appraisalOrder.address.lng = position.lng();
                        saveAddressPosition();
                    }
                });
            } else if (vm.addressUpdatedType === 2) { // 2: use lat/lng set from map
                saveAddressPosition();
            }
        }

        function saveAddressPosition() {
            AppraisalOrder.setAddressPosition(
                { id: vm.appraisalOrder.id },
                { lat: vm.appraisalOrder.address.lat, lng: vm.appraisalOrder.address.lng });
        }

        function canSubmitThirdParty() {
            return vm.currentForm === 'thirdParty' && !vm.isReadOnly && !vm.isSaving                    // is third party, not saving, not read-only
                && vm.formData.report && (/^AVM.*/.test(vm.appraisalOrder.appraisalTypeOptions) || vm.formData.estimatedValue || vm.formData.estimatedValueMax)
                && vm.formData.completionDate                                 // contains required fields
                && (!AppraisalState.inAnyState(vm.appraisalOrder, AppraisalState.submitted)
                        || vm.appraisalOrder.state === 'RESUBMISSION_REQUIRED')                        // in valid state
                && !vm.hasErrors;                                                                      // no blocking errors
        }

        function submitReport(ev) {

        	if(vm.appraisalOrder.lender && vm.appraisalOrder.lender.sendReportToCCR){
        		var confirm = $mdDialog.confirm(ev)
                	.title('Submit Report')
                	.htmlContent("By selecting 'OK' you are acknowledging your confidence in the report being submitted.<br><font color=\"red\"> ATTENTION: This lender does not receive reports automatically through Value Connect. Please send to lender directly and cc appraisals@valueconnect.ca</font>")
                	.ariaLabel('Submit Report')
                	.targetEvent(ev)
                	.ok('OK')
                	.cancel('Cancel');
        	}else{
        		var confirm = $mdDialog.confirm(ev)
            	.title('Submit Report')
            	.htmlContent("By selecting 'OK' you are acknowledging your confidence in the report being submitted.<br> Your report will be sent to the lender through the VC portal. No further action is required. Thank you!")
            	.ariaLabel('Submit Report')
            	.targetEvent(ev)
            	.ok('OK')
            	.cancel('Cancel');

        	}

            saveData(true).then(function(result) {
                $mdDialog.show(confirm).then(function() {
                    AppraisalReport.submitReport({id:vm.appraisalReport.id}, {}, function(data) {
                        $state.go("home");
                    });
                });
            });
        }

        // TEMPLATES
        function saveTemplate() {
          vm.formData.$promise.then(function (data) {
              if (data) {
                  $mdDialog.show({
                      templateUrl: 'app/entities/template/template-dialog.html',
                      controller: 'TemplateDialogController',
                      controllerAs: 'vm',
                      resolve: {
                          entity: function () {
                              return {
                                  name: null,
                                  formType: vm.formDefinitions[0].name,
                                  section: vm.currentSection,
                                  data: JSON.stringify(getModifiedData(vm.reportData[vm.currentForm][vm.currentSection], new FormData(), vm.currentSection, true)),
                                  shared: false
                              };
                          },
                          formType: function() {
                              return vm.formDefinitions[0].name;
                          },
                          section: function() {
                              return vm.currentSection;
                          }
                      },
                      translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                          return $translate.refresh();
                      }]
                  });
              }
          });
        }

        function loadExistingTemplate() {
            $uibModal.open({
                templateUrl: 'app/entities/template/template-select.html',
                controller: 'TemplateSelectController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    formType: function() {
                        return vm.formDefinitions[0].name;
                    },
                    section: function() {
                        return vm.currentSection;
                    }
                }
            }).result.then(function(templateResult) {
                clearSectionArrays();
                return save(true).then(function() {
                    return Template.loadTemplateData({templateId: templateResult, reportId: vm.appraisalReport.id}, null).$promise
                });
            }).then(function() {
                $scope.editForm.$setPristine();
                $state.reload();
            });
        }

        function clearSectionArrays() {
            Object.keys(vm.reportData[vm.currentForm][vm.currentSection]).forEach(function(key) {
                if (Array.isArray(vm.reportData[vm.currentForm][vm.currentSection][key])) {
                    vm.reportData[vm.currentForm][vm.currentSection][key].forEach(function() {
                        removeItem(vm.reportData[vm.currentForm][vm.currentSection][key], 0);
                    })
                }
            });
        }

        function removeEntries(values, removeEntries) {
            if (values && values.length > 0) {
                for (var i = values.length-1; i >= 0; i--) {
                    for (var r = 0; r < removeEntries.length; r++) {
                        if (values[i] === removeEntries[r]) {
                            values.splice(i, 1);
                            break;
                        }
                    }
                }
            }
        }

        function existsInArray(key, value, array) {
            return array.some(function(item) {
                return item[key] === value;
            })
        }

        function setPlaceholderLenderName(order) {
            if (order.lender == null) {
                order.lender = {
                    lenderName: 'Value Connect'
                }
            }
        }
    }
})();
