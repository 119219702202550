(function() {
    'use strict';

    angular
        .module('valueconnectApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngMaterial',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ngFileSaver',
            'ngMessages',
            'ngSanitize',
            'ngMap',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'ui.select',
            'ui.calendar',
            'ui.mask',
            'ui.utils.masks',
            'ui.tinymce',
            'infinite-scroll',
            'vcRecaptcha',
            'angular-loading-bar',
            'lfNgMdFileInput',
            'sticky',
            'toggleForm',
            'vcDialog',
            'passwordCheck',
            'fcsa-number',
            'dndLists',
            'angularMoment',
            'xeditable',
            'valueconnectApp.auditEntry',
            'valueConnectAppraisalOrderGrid',
            'valueConnectPendingRegistrations',
            'valueConnectPendingChangeRequests',
            'valueConnectPendingAppraiserMessages',
            'valueConnectPendingOriginatorMessages',
            'valueConnectPendingConcerns',
            'ngIdle',
            'valueconnectApp.validationSummary',
            'valueconnectApp.reportErrorSummary',
            'angAccordion',
            'toastr',
            'ngRaven'

            // jhipster-needle-angularjs-add-module JHipster will add new module here
        ])
        .config(config)
        .run(run);

    config.$inject = ['IdleProvider', 'KeepaliveProvider'];
    function config(IdleProvider, KeepaliveProvider) {
        // configure Idle settings
        // TODO: This should be in a seperate config file
        IdleProvider.idle(15*60); // in seconds
        IdleProvider.timeout(5*60); // in seconds
        KeepaliveProvider.interval(10*60); // in seconds
    }

    run.$inject = ['stateHandler', 'translationHandler', 'Idle', 'SENTRY_ENABLED'];
    function run(stateHandler, translationHandler, Idle, SENTRY_ENABLED) {
        stateHandler.initialize();
        translationHandler.initialize();
        Raven.setShouldSendCallback(function() { return SENTRY_ENABLED });

        // start watching when the app runs. also starts the Keepalive service by default.
        //Idle.watch();
    }

})();
