(function () {
    'use strict';

    angular
        .module('valueconnectApp')
        .factory('User', User);

    User.$inject = ['$resource'];

    function User ($resource) {
        var service = $resource('api/users/:login', {}, {
            'query': {method: 'GET', isArray: true},
            'queryManaged': { method: 'GET', url: 'api/users/managed', isArray: true},
            'getOriginators': {method: 'GET', url:'api/users/originators', isArray: true },
            'getOriginatorsPageable': {method: 'GET', url:'api/users/originators-pageable', isArray: true },
            'getAppraisers': {method: 'GET', url:'api/users/appraisers', isArray: true },
            'countOriginators' : { method: 'GET', url:'api/users/originators/count'},
            'countAppraisers' : { method: 'GET', url:'api/users/appraisers/count' },
            'getIntegrationId' : {method: 'GET', url: 'api/users/getIntegrationId/:id',
                responseType:'long',
                transformResponse: function(data, headersGetter) { return { data : data }; }},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }, url: 'api/users/:login/get-user'
            },
            'save': { method:'POST' },
            'update': { method:'PUT' },
            'getPendingRegistrations': { method: 'GET', url:'api/pending-registrations/', isArray: true },
            'getCurrentUserManager' : {
                method: 'GET',
                url:'api/manager-for-user',
                transformResponse: function (data) {
                    data = data !== "" ? angular.fromJson(data) : null;
                    return data;
                }
            },
            'getByAuthority' : {method: 'GET', url:'api/users/authority/:authority', isArray: true }
        });
        return service;
    }
})();
