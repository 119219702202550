(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('CancelAppraisalOrderDialogController',CancelAppraisalOrderDialogController);

    CancelAppraisalOrderDialogController.$inject = ['$mdDialog', '$scope', '$timeout', '$state', 'entity', 'AppraisalOrder'];

    function CancelAppraisalOrderDialogController($mdDialog, $scope, $timeout, $state, entity, AppraisalOrder) {
        var vm = this;
        vm.cancelAppraisalOrder = entity;
        vm.clear = clear;
        vm.save = save;

        function save() {
            $scope.isSaving = true;

            if (vm.cancelAppraisalOrder.reason === 'Price too high') {
                $mdDialog.show({
                    controller: 'CancelAppraisalOrderConfirmationDialogController',
                    controllerAs: 'vm',
                    templateUrl: 'app/entities/appraisal-order/cancel-appraisal-order-confirmation-dialog.html',
                    animation: true,
                    backdrop: 'static',
                    clickOutsideToClose: true,
                    locals: {
                        entity: vm.cancelAppraisalOrder
                    }
                })
            } else {
                AppraisalOrder.cancel(vm.cancelAppraisalOrder, function (data) {
                    $mdDialog.cancel('cancel');
                    $timeout(function () {
                        $state.go("home");
                    });
                });
            }
        }

        function clear () {
            $mdDialog.cancel('cancel');
        }

    }
})();
