(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .factory('Principal', Principal);

    Principal.$inject = ['$q', 'Account'];

    function Principal ($q, Account) {
        var _identity,
            _authenticated = false;

        var service = {
            authenticate: authenticate,
            hasAnyAuthority: hasAnyAuthority,
            hasAuthority: hasAuthority,
            matchAnyAuthority: matchAnyAuthority,
            hasRole: hasRole,
            hasAnyRole: hasAnyRole,
            entityAuthorities: entityAuthorities,
            getBrokerageId: getBrokerageId,
            getAppraisalFirmId: getAppraisalFirmId,
            identity: identity,
            isAuthenticated: isAuthenticated,
            isIdentityResolved: isIdentityResolved
        };

        return service;

        function authenticate (identity) {
            _identity = identity;
            _authenticated = identity !== null;
        }

        /**
         * Check if the current use has any of the specified authorities
         * @param  {Array}  authorities An array of authority strings
         * @return {Boolean}            True, if the authority check passed, otherwise false
         */
        // TODO: don't allow regexes here, use hasMatchAuthority instead
        function hasAnyAuthority (authorities) {
            if (!_authenticated || !_identity || !_identity.authorities) {
                return false;
            }

            for (var i = 0; i < authorities.length; i++) {
                // TODO: remove this block
                if (angular.isObject(authorities[i])) {
                    for (var j = 0; j < _identity.authorities.length; j++) {
                        if (authorities[i].test(_identity.authorities[j].trim())) {
                            return true;
                        } else if (authorities[i].test(_identity.authorities[j].trim())) { //For some unkown reason the first check intermittenly fails...
                            return true;
                        }
                    }
                }

                if (_identity.authorities.indexOf(authorities[i]) !== -1) {
                    return true;
                }
            }

            return false;
        }

        function hasAuthority (authority) {
            if (!_authenticated) {
                return $q.when(false);
            }

            return this.identity().then(function(_id) {
                return _id.authorities && _id.authorities.indexOf(authority) !== -1;
            }, function(){
                return false;
            });
        }

        /**
         * Check if the current user has any role that matches the authotity
         * @param  {RegExp} regex The regular expression to test. Note: do not use global flag
         * @return {Promise}      Promise that is resolved with the result of the test (Boolean)
         */
        function matchAnyAuthority(regex) {
            if (!_authenticated) {
                return $q.when(false);
            }

            return this.identity().then(function(_id) {
                for (var i = 0; i < _id.authorities.length; i++) {
                    if (regex.test(_id.authorities[i] + "")) {
                        return true;
                    // TODO: remove this once we've gotten rid of all the global flags
                    } else if (regex.test(_id.authorities[i] + "")) {
                        return true;
                    }
                }

                return false;
            }, function(){
                return false;
            });
        }

        function roleToAuthority(role) {
            return "ROLE_" + role;
        }

        function hasRole(role) {
            return this.hasAuthority(roleToAuthority(role));
        }

        function hasAnyRole(roles) {
            return this.hasAnyAuthority(roles.map(roleToAuthority));
        }

        function entityAuthorities(authType) {
            return this.identity().then(function(account) {
                if(!account || !account.entityAuthorities) return [];

                return account.entityAuthorities.filter(function(auth) {
                    return auth.authorityType === authType;
                });
            });
        }

        /**
         * Retrieve the brokerage for the current user.
         * Assumes that each user has entity authorities for only a single brokerage
         * @return {int}  The id of the brokerage, or null if the user has no brokerage authorities
         */
        function getBrokerageId() {
            return this.entityAuthorities("BROKERAGE").then(function(authorities) {
                return (authorities && authorities.length > 0) ? authorities[0].brokerageId : null;
            });
        }

        /**
         * Retrieve the appraisal firm for the current user.
         * Assumes that each user has entity authorities for only a single appraisal firm
         * @return {int}  The id of the appraisal firm, or null if the user has no brokerage authorities
         */
        function getAppraisalFirmId() {
            return this.entityAuthorities("APPRAISAL_FIRM").then(function(authorities) {
                return (authorities && authorities.length > 0) ? authorities[0].appraisalFirmId : null;
            });
        }

        function identity (force) {
            var deferred = $q.defer();

            if (force === true) {
                _identity = undefined;
            }

            // check and see if we have retrieved the identity data from the server.
            // if we have, reuse it by immediately resolving
            if (angular.isDefined(_identity)) {
                deferred.resolve(_identity);

                return deferred.promise;
            }

            // retrieve the identity data from the server, update the identity object, and then resolve.
            Account.get().$promise
                .then(getAccountThen)
                .catch(getAccountCatch);

            return deferred.promise;

            function getAccountThen (account) {
                // Explicitly refuse to let ROLE_MOBILE_APPRAISER into the VC portal.
                if (account.data.authorities.some(function (e) {
                    return e === 'ROLE_MOBILE_APPRAISER';
                })) {
                    _identity = null;
                    _authenticated = false;
                    deferred.resolve(_identity);
                } else {
                    _identity = account.data;
                    _authenticated = true;
                    deferred.resolve(_identity);
                }
            }

            function getAccountCatch () {
                _identity = null;
                _authenticated = false;
                deferred.resolve(_identity);
            }
        }

        function isAuthenticated () {
            return _authenticated;
        }

        function isIdentityResolved () {
            return angular.isDefined(_identity);
        }
    }
})();
