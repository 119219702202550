(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .controller('CancelAppraisalOrderConfirmationDialogController',CancelAppraisalOrderConfirmationDialogController);

    CancelAppraisalOrderConfirmationDialogController.$inject = ['$mdDialog', '$scope', '$timeout', '$state', '$window', 'entity', 'AppraisalOrder', 'User'];

    function CancelAppraisalOrderConfirmationDialogController($mdDialog, $scope, $timeout, $state, $window, entity, AppraisalOrder, User) {
        var vm = this;
        vm.cancelAppraisalOrder = entity;
        vm.clear = clear;
        vm.save = save;

        vm.ccr = User.getCurrentUserManager();
        vm.getFormattedCcrPhoneNumber = getFormattedCcrPhoneNumber;
        vm.openCallApp = openCallApp;
        vm.openCancellationConcernEmail = openCancellationConcernEmail;

        function getFormattedCcrPhoneNumber() {
            const phoneNumberNumbersOnly = vm.ccr.phoneNumber.replace(/[^0-9]/g, '');
            const formattedPhoneNumber = '(' + phoneNumberNumbersOnly.slice(0, 3) + ') ' + phoneNumberNumbersOnly.slice(3, 6) + '-' + phoneNumberNumbersOnly.slice(6, 10);
            if (phoneNumberNumbersOnly.length > 10) {
                return formattedPhoneNumber + ' x' + phoneNumberNumbersOnly.slice(10);
            }
        }

        function openCallApp() {
            var phoneNumberAsLink = "tel:" + getFormattedCcrPhoneNumber().replace(/x/g, ',');
            $window.open(phoneNumberAsLink, "_blank");
            $mdDialog.cancel();
        }

        function openCancellationConcernEmail() {
            $window.open("mailto:" + vm.ccr.email + "?subject=%23" + vm.cancelAppraisalOrder.id + "%20Price%20Concerns&body=Please%20describe%20(below)%20the%20pricing%20concern%20or%20expectations%20you%20have%20for%20the%20order", "_blank");
            $mdDialog.cancel();
        }

        function save() {
            $scope.isSaving = true;

            AppraisalOrder.cancel(vm.cancelAppraisalOrder, function(data) {
                    $mdDialog.cancel('cancel');
                   $timeout(function(){
                        $state.go("home");
                    });
               });
        }

        function clear () {
            $mdDialog.cancel('cancel');
        }

    }
})();
