(function() {
    'use strict';

    angular
        .module('valueconnectApp')
        .factory('Auth', Auth);

    Auth.$inject = ['$rootScope', '$state', '$http', '$sessionStorage', '$localStorage', '$q', '$translate', 'Principal', 'AuthServerProvider', 'Account', 'Register', 'Activate', 'Password', 'PasswordResetInit', 'PasswordResetFinish', 'Idle'];

    function Auth ($rootScope, $state, $http, $sessionStorage, $localStorage, $q, $translate, Principal, AuthServerProvider, Account, Register, Activate, Password, PasswordResetInit, PasswordResetFinish, Idle) {
        var service = {
            activateAccount: activateAccount,
            authorize: authorize,
            getRedirectError: getRedirectError,
            changePassword: changePassword,
            createAccount: createAccount,
            getPreviousState: getPreviousState,
            login: login,
            logout: logout,
            resetPasswordFinish: resetPasswordFinish,
            resetPasswordInit: resetPasswordInit,
            resetPreviousState: resetPreviousState,
            storePreviousState: storePreviousState,
            updateAccount: updateAccount,
            isLoggingOut: isLoggingOut,
            initProviders: initProviders,
            authProvider: authProvider,
            authProviderCss: authProviderCss,
            loginWithProviderRedirect: loginWithProviderRedirect,
            checkRedirectResult: checkRedirectResult,
            processLogin: processLogin,
            getProvider: getProvider,
        };

        var redirectError = null;
        var loggingOut = false;

        var providers = {};
        providers["microsoft.com"] = new firebase.auth.OAuthProvider("microsoft.com");

        return service;

        function activateAccount (key, callback) {
            var cb = callback || angular.noop;

            return Activate.get(key,
                function (response) {
                    return cb(response);
                },
                function (err) {
                    return cb(err);
                }.bind(this)).$promise;
        }

        function authorize (force) {
            var authReturn = Principal.identity(force).then(authThen);

            return authReturn;

            function authThen (identity) {
                var isAuthenticated = Principal.isAuthenticated();
                redirectError = null;

                if (isAuthenticated) $rootScope.$emit('authenticationSuccess');

                // Set Sentry user context
                Raven.setUserContext(isAuthenticated ? { id: identity.id } : null);

                // an authenticated user can't access to login and register pages
                if (isAuthenticated && $rootScope.toState.parent === 'account' && ($rootScope.toState.name === 'login' || $rootScope.toState.name === 'register')) {
                    $state.go('home');
                }

                // TODO: recover to previous page after accepting terms

                // Force redirects to terms and conditions until the user accepts
                if (isAuthenticated && !identity.isTermsAccepted) {
                    if($rootScope.toState.name === 'terms') return;

                    // Display an error only if this is an unexpected redirect to the terms page
                    if($rootScope.fromState.name !== 'terms' && $rootScope.fromState.name !== 'home' ||
                        $rootScope.fromState.name === 'terms' && $rootScope.toState.name !== 'terms') {
                        redirectError = 'terms';
                    }

                    storePreviousState($rootScope.toState.name, $rootScope.toStateParams);
                    $state.go('terms');
                    return;
                }

                // recover and clear previousState after external login redirect (e.g. oauth2)
                if (isAuthenticated && !$rootScope.fromState.name && getPreviousState()) {
                    var previousState = getPreviousState();
                    resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }

                if ($rootScope.toState.data.authorities && $rootScope.toState.data.authorities.length > 0 && !Principal.hasAnyAuthority($rootScope.toState.data.authorities)) {
                    if (isAuthenticated) {
                        // user is signed in but not authorized for desired state
                        $state.go('accessdenied');
                    }
                    else {
                        // user is not authenticated. stow the state they wanted before you
                        // send them to the login service, so you can return them when you're done
                        storePreviousState($rootScope.toState.name, $rootScope.toStateParams);

                        // now, send them to the signin state so they can log in
                        $state.go('login').then(function() {
                            $rootScope.notAuthorized = true;
                        });
                    }
                }
            }
        }

        function getRedirectError() {
            return redirectError;
        }

        function changePassword (newPassword, callback) {
            var cb = callback || angular.noop;

            return Password.save(newPassword, function () {
                return cb();
            }, function (err) {
                return cb(err);
            }).$promise;
        }

        function createAccount (account, callback) {
            var cb = callback || angular.noop;

            return Register.save(account,
                function () {
                    return cb(account);
                },
                function (err) {
                    this.logout();
                    return cb(err);
                }.bind(this)).$promise;
        }

        function login (credentials, callback) {
            var cb = callback || angular.noop;
            var deferred = $q.defer();

            AuthServerProvider.login(credentials)
                .then(loginThen)
                .catch(function (err) {
                    this.logout();
                    deferred.reject(err);
                    throw err;
                }.bind(this));

            function loginThen (data) {
                Principal.identity(true).then(function(account) {
                    // After the login the language will be changed to
                    // the language selected by the user during his registration
                    if (account!== null) {
                        $translate.use(account.langKey).then(function () {
                            $translate.refresh();
                        });
                    }
                    deferred.resolve(data);
                });
                return cb();
            }

            return deferred.promise;
        }

        function logout () {
            loggingOut = true;
            AuthServerProvider.logout().finally(function() {
                loggingOut = false;

                // Ensure firebase authentication is cleared
                firebase.auth().signOut();
            });
            Principal.authenticate(null);
            Idle.unwatch();
        }

        function isLoggingOut () {
            return loggingOut;
        }

        function resetPasswordFinish (keyAndPassword, callback) {
            var cb = callback || angular.noop;

            return PasswordResetFinish.save(keyAndPassword, function () {
                return cb();
            }, function (err) {
                return cb(err);
            }).$promise;
        }

        function resetPasswordInit (mail, callback) {
            var cb = callback || angular.noop;

            return PasswordResetInit.save(mail, function() {
                return cb();
            }, function (err) {
                return cb(err);
            }).$promise;
        }

        function updateAccount (account, callback) {
            var cb = callback || angular.noop;

            return Account.save(account,
                function () {
                    return cb(account);
                },
                function (err) {
                    return cb(err);
                }.bind(this)).$promise;
        }

        function getPreviousState() {
            var previousState = $sessionStorage.previousState;
            return previousState;
        }

        function resetPreviousState() {
            delete $sessionStorage.previousState;
        }

        function storePreviousState(previousStateName, previousStateParams) {
            var previousState = { "name": previousStateName, "params": previousStateParams };
            $sessionStorage.previousState = previousState;
        }

        function getProvider(name) {
            return providers[name];
        }

        function initProviders() {
            getProvider("microsoft.com").setCustomParameters({
                // Allow the user to switch if needed.
                prompt: "select_account",
            });
        }

        function authProvider() {
            return $localStorage["auth-provider"];
        }

        function authProviderCss() {
            return $localStorage["auth-provider"] && $localStorage["auth-provider"] !== "password" ? $localStorage["auth-provider"].replace(/[^a-zA-Z0-9_]/, "-") : null;
        }

        function loginWithProviderRedirect(provider) {
            firebase.auth().signInWithRedirect(provider)
        }

        function checkRedirectResult(loginVm, callback, errorCallback, loginExceptionHandler) {
            firebase.auth().getRedirectResult()
            .then(function (result) {
                if (callback && typeof(callback) === 'function') {
                    callback(result);
                }

                if (result && result.user) {
                    $localStorage["auth-provider"] = result.additionalUserInfo.providerId;
                    result.user.getIdToken()
                        .then(function (idToken) {
                            processLogin(idToken, loginVm, loginExceptionHandler)
                        });
                }

            })
            .catch(function (err) {
                if (errorCallback && typeof(errorCallback) === 'function') {
                    errorCallback(err);
                }
            });
        }

        function processLogin(idToken, loginVm, loginExceptionHandler) {
            return $http.post('api/authenticate', {
                idToken: idToken,
                email: loginVm.email
            }).then(function (authResult) {
                Principal.identity(true).then(function (account) {
                    if (!getPreviousState()) {
                        $state.go('home');
                    } else {
                        // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                        // since login is succesful, go to stored previousState and clear previousState
                        var previousState = getPreviousState();
                        var statename = previousState.name === 'login' ? 'home' : previousState.name;
                        resetPreviousState();
                        $state.go(statename, previousState.params);
                    }
                    if (account) $rootScope.$broadcast('authenticationSuccess');
                });

            }).catch(function (error) {
                if (loginExceptionHandler && typeof(loginExceptionHandler === 'function')) {
                    loginExceptionHandler(error);
                }
            });
        }
    }
})();
